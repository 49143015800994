const {animationConfig} = require('/js/animations/config.animation');

setTimeout(() => {
    const shortNav = document.querySelector('#indexBody #shortNav')
    const nav = document.querySelector('#indexBody #navbar')
    const front = document.querySelector('#indexBody #parallaxFront')

    if (shortNav) {
        Animation.animate(animationConfig.showEL([shortNav, nav, front], 1000), [shortNav, nav, front])
    }
}, 1000)

setTimeout(() => {
    Animation.animate(animationConfig.wave)
}, 1000)

setInterval(() => {
    Animation.animate(animationConfig.wave)
}, 2000)

